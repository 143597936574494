import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function ApprovalStatus() {
  const navigate = useNavigate();
  const [isUserApproved, setIsUserApproved] = useState<boolean | null>(null);
  const { t } = useTranslation();

  const stepsApproved = [
    { label: "Registration form", status: "Done" },
    { label: "Admin approval", status: "Done" },
    { label: "Account created", status: "Done" },
  ];

  const stepsPending = [
    { label: "Registration form", status: "Done" },
    { label: "Admin approval", status: "Pending" },
    { label: "Account created", status: "Pending" },
  ];

  useEffect(() => {
    // Retrieve the approval status from local storage on component mount
    const approvalStatus = localStorage.getItem("isUserApproved");
    setIsUserApproved(approvalStatus === "true"); // Convert string to boolean
  }, []);

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div className="gradient flex min-h-screen flex-col items-center justify-center lg:flex-row">
      <div className="flex w-full flex-col items-center justify-center p-12">
        <div>
          <h1 className="mb-8 text-2xl font-bold text-white">
            {" "}
            {localStorage.getItem("logo") ? (
              <img
                style={{ maxHeight: "54px", width: "auto", display: "block" }}
                alt=""
                src={localStorage.getItem("logo") || undefined}
              />
            ) : (
              t("common.PRODUCT_TITLE")
            )}
          </h1>
        </div>
        <div className="w-1/2 rounded-md bg-white p-8 text-center shadow-lg">
          <div className="mb-4 flex items-center justify-center">
            <h2 className="text-blue-color mb-4 text-xl font-bold">
              Approval status
            </h2>
          </div>
          <div className="flex items-center justify-between pb-6">
            {(isUserApproved ? stepsApproved : stepsPending).map(
              (step, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div
                    className={`flex h-10 w-10 items-center justify-center rounded-full ${
                      step.status === "Done"
                        ? "button-color text-white"
                        : "border border-gray-300 bg-gray-100 text-gray-500"
                    }`}
                  >
                    {step.status === "Done" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        className="h-6 w-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    ) : (
                      <div className="h-2 w-2 bg-transparent" />
                    )}
                  </div>
                  <div className="mt-2 text-center">
                    <span className="font-bold text-black">{step.label}</span>
                    <span
                      className={`block ${step.status === "Done" ? "text-green-500" : "text-gray-400"}`}
                    >
                      {step.status}
                    </span>
                  </div>
                  {index < stepsPending.length - 1 && (
                    <div className="h-1 flex-1 bg-gray-200" />
                  )}
                </div>
              ),
            )}
          </div>
          <button
            className="button-color w-full rounded p-3 font-semibold text-white"
            onClick={navigateToHome}
          >
            Explore Products
          </button>
          <p className="mt-3 text-gray-600">
            Meanwhile, you can still explore products and brands!
          </p>
        </div>
      </div>
    </div>
  );
}
