import React, { useEffect, useState } from "react";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useSearchParams, useNavigate } from "react-router-dom";
import apiService from "../../services/apiService";
import helperService from "../../services/helperService";
import { PAGINATION_CONSTANTS } from "../../shared/constants/globalConstants";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import slugify from "slugify";

const SearchedProducts = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const searchText = searchParams.get("searchText");
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.PAGE);
  const per_page = PAGINATION_CONSTANTS.PER_PAGE; // Number of products per page
  const navigate = useNavigate(); // Initialize useNavigate
  const [endOfScrollData, setEndOfScrollData] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);

  // Fetch searched products when searchText changes
  useEffect(() => {
    setProducts([]); // Clear previous results when searchText changes
    setPage(1); // Reset to page 1 when searchText changes
    fetchSearchedProducts(true); // Initial fetch
  }, [searchText]);

  // Fetch searched products from the API
  // Searched products are final level of categories whith will have final prodects of diffent sizes inside them
  const fetchSearchedProducts = async (loader: boolean = false) => {
    loader ? setLoading(true) : setLoading(false);
    try {
      const res: any = await apiService.getSearchedProducts(
        searchText || "",
        page,
        per_page,
      );
      const products = res.data.categories;
      // If fewer products are returned than per_page, it means there's no more data
      if (products.length < per_page) setEndOfScrollData(true);

      // Append new products to the list
      setProducts((prevProducts) => [...prevProducts, ...products]);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const navigateToProductPage = (product: any) => {
    const formattedName = slugify(product.name, { replacement: "&" });
    navigate(`/product/${formattedName}/${product.category_id}`);
  };

  useEffect(() => {
    if (scrolled) {
      const loader = false;
      // Fetch categories only if scrolled
      fetchSearchedProducts(loader); // Will use the updated 'page' from state
    }
  }, [page]);

  const fetchMoreData = async () => {
    setScrolled(true);
    loadMoreProducts(); // Increment page, and useEffect will handle fetching the data
  };

  const loadMoreProducts = () => {
    setPage((prevPage) => prevPage + 1); // Increment the page
  };

  return (
    <div className="mx-auto w-full">
      {/* Loader while fetching data */}
      {loading ? (
        <div
          className="flex items-center justify-center pt-64"
          data-testid="clip-loader"
        >
          <ClipLoader color="#0000ff" loading={loading} size={50} />
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-semibold">{searchText}</h1>
          <p className="mt-1 text-gray-500">
            {products.length} {t("common.TOTAL_PRODUCTS")}
          </p>
          <InfiniteScroll
            dataLength={products.length}
            next={fetchMoreData}
            hasMore={!endOfScrollData}
            loader={
              <div
                className="my-4 flex items-center justify-center"
                data-testid="clip-loader"
              >
                <ClipLoader color="#0000ff" loading={true} size={30} />
              </div>
            }
            height="60vh"
            scrollThreshold={0.9}
            endMessage={
              <div className="my-4 flex items-center justify-center">
                <p className="text-center font-medium text-gray-600">
                  {products.length > 0
                    ? t("common.END_OF_DATA_MESSAGE")
                    : t("common.NO_DATA_AVAILABLE")}
                </p>
              </div>
            }
            className="mt-4 border-t border-gray-200"
          >
            {products.map((product, index) => (
              <div
                key={index}
                className="flex items-center justify-between border-b border-gray-200 py-4"
                onClick={() => navigateToProductPage(product)}
              >
                <span className="font-semibold">{product.name}</span>
                <ChevronRightIcon
                  width={20}
                  height={20}
                  className="text-gray-400"
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default SearchedProducts;
