import React, { useState, useEffect } from "react";
import apiService from "../../services/apiService";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import helperService from "../../services/helperService";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { ReactComponent as DefaultProductImage } from "../../assets/images/default-product-image.svg";
import { PAGINATION_CONSTANTS } from "../../shared/constants/globalConstants";
import { useTranslation } from "react-i18next";
import slugify from "slugify";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";

const Category = () => {
  const { t } = useTranslation();
  const navigate = useNavigate(); // Initialize useNavigate
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryTitle, setCategoryTitle] = useState<string>(
    t("common.ALL_PRODUCTS"),
  );
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.PAGE); // Track page number
  const per_page = PAGINATION_CONSTANTS.PER_PAGE; // Number of items per page
  const [endOfScrollData, setEndOfScrollData] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [rootCategoryNo, setRootCategoryNo] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState<[]>([]);
  const [categories, setCategories] = useState<any>([]);
  const { categoryName, categoryId } = useParams<{
    categoryName?: string;
    categoryId?: string;
  }>();
  const [files, setFiles] = useState([]);
  const location = useLocation();

  useEffect(() => {
    resetState();
    selectCategoryToFetch();
  }, [location]);

  const selectCategoryToFetch = () => {
    if (location.pathname === ("/category/root" || "/")) {
      fetchRootCategories();
    } else if (categoryName && categoryId) {
      getSubCategory(categoryId);
    } else {
      navigate("/category/root");
    }
  };

  //resest relevent state on category change
  const resetState = () => {
    setBreadcrumb([]);
    setCategories([]);
    setPage(1);
    setEndOfScrollData(false);
    setRootCategoryNo(null);
  };

  //fetch root category
  const fetchRootCategories = async (loader?: boolean) => {
    loader === false ? setLoading(false) : setLoading(true);
    try {
      const res: any = await apiService.getRootCategories(page, per_page);
      const { categories } = res.data;
      setRootCategoryNo(res.data.total);
      setFiles([]);
      categories.length < per_page && setEndOfScrollData(true);
      setCategoryTitle(t("common.ALL_PRODUCTS"));
      setCategories((prev: any) => [...prev, ...categories]);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //fetch nested category
  const getSubCategory = async (categoryId: string) => {
    setLoading(true);
    try {
      const res: any = await apiService.getSubCategories(categoryId);
      const category = res.data;
      const subCategories = res.data.categories;
      setFiles(res.data.files);
      //if final level of category which is product then navigate to product page
      if (subCategories.length === 0) {
        navigateToProductPage(category);
        return;
      }
      setBreadcrumbData(category);
      setCategoryTitle(res.data.name);
      setCategories(subCategories);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //fetch nested category
  const getSubCategoryOnScroll = async (categoryId: string) => {
    try {
      const res: any = await apiService.getSubCategories(
        categoryId,
        page,
        per_page,
      );
      const { categories } = res.data;
      categories.length < per_page && setEndOfScrollData(true);
      setCategories((prev: any) => [...prev, ...categories]);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    }
  };

  //on update of category update the state of breadcrumb data
  const setBreadcrumbData = async (category: any) => {
    setLoading(true);
    try {
      const res: any = await apiService.getBreadcrumbData(category.category_id);
      const breadcrumbData = res.data;
      breadcrumbData.unshift({ category_id: "root", name: "Products" });
      setBreadcrumb(breadcrumbData);
    } catch (error) {
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  //handle navigation sub category
  const navigateToSubCategory = async (category: any) => {
    const formattedName = slugify(category.name, { replacement: "&" });
    navigate(`/category/${formattedName}/${category.category_id}`);
  };

  //if there is no further sub categories then navigae to product
  const navigateToProductPage = (category: any) => {
    const formattedName = slugify(category.name, { replacement: "&" });
    navigate(`/product/${formattedName}/${category.category_id}`);
  };

  useEffect(() => {
    if (scrolled) {
      // Fetch categories only if scrolled
      setScrolled(false);
      const loader = false;
      if (categoryId) {
        getSubCategoryOnScroll(categoryId);
      } else {
        fetchRootCategories(loader); // Will use the updated 'page' from state
      }
    }
  }, [page]); // Trigger effect when 'page' changes

  //feth more data on user scroll
  const fetchMoreData = async () => {
    setScrolled(true);
    updatePageNo(); // Increment page, and useEffect will handle fetching the data
  };

  //update page number on page scroll
  const updatePageNo = () => {
    setPage((prevPage) => prevPage + 1); // Increment the page
  };

  const handleBreadcrumbClick = (item: any) => {
    helperService.handleBreadcrumbClick(item, navigate);
  };

  return (
    <div className="mx-auto">
      {/* Loader covering the entire section */}
      {loading ? (
        <div
          className="flex items-center justify-center pt-64"
          data-testid="clip-loader"
        >
          {" "}
          {/* Center loader vertically */}
          <ClipLoader color="#0000ff" loading={loading} size={50} />
        </div>
      ) : (
        <>
          <div>
            <div className="flex flex-wrap items-center gap-3">
              <h2 className="pr-3 text-xl font-bold lg:text-2xl">
                {categoryTitle}
              </h2>

              <div className="flex max-h-[70px] max-w-[700px] flex-wrap gap-2 overflow-x-auto whitespace-nowrap">
                {files?.map((i: any) => (
                  <a
                    key={i.category_media_id}
                    href={i.media_link}
                    title={i.title}
                    target="_blank"
                    rel="noreferrer"
                    className="flex items-center gap-1 rounded border px-2 py-1 transition hover:bg-gray-100"
                  >
                    <DocumentArrowDownIcon width={20} height={20} />
                    <span className="max-w-[150px] truncate text-sm">
                      {i.title}
                    </span>
                  </a>
                ))}
              </div>
            </div>
            {/* <h1 className="mb-4 text-2xl font-bold">{categoryTitle}</h1> */}
            {/* Conditionally Render Breadcrumb */}
            {breadcrumb.length > 1 && (
              <Breadcrumb
                breadcrumb={breadcrumb}
                onClick={handleBreadcrumbClick}
              />
            )}
            {rootCategoryNo !== null && (
              <p className="mt-1 pb-2 text-gray-500">
                {rootCategoryNo} {t("common.TOTAL_PRODUCTS")}
              </p>
            )}
          </div>
          <hr />
          <InfiniteScroll
            dataLength={categories.length}
            next={fetchMoreData}
            hasMore={!endOfScrollData}
            loader={
              <div
                className="flex h-64 items-center justify-center"
                data-testid="clip-loader"
              >
                <ClipLoader color="#0000ff" loading={true} size={30} />
              </div>
            }
            height="70vh"
            scrollThreshold={0.8}
            endMessage={
              <div className="flex h-64 items-center justify-center">
                {" "}
                {/* Set a height for vertical centering */}
                <p className="text-center font-medium text-gray-600">
                  {categories.length > 0
                    ? t("common.END_OF_DATA_MESSAGE")
                    : t("common.NO_DATA_AVAILABLE")}
                </p>
              </div>
            }
            className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
          >
            {categories.map((category: any) => (
              <div
                key={category.category_id}
                onClick={() => navigateToSubCategory(category)}
                className="aspect-[5/4]"
              >
                <div className="m-4 aspect-[5/4] cursor-pointer overflow-hidden rounded-lg border border-solid shadow transition hover:shadow-lg">
                  {/* Conditionally render the actual image if media_link exists, otherwise show the DefaultProductImage */}
                  {category?.media?.media_link ? (
                    <img
                      src={category.media.media_link}
                      alt={category.name}
                      className="h-full w-full"
                    />
                  ) : (
                    <DefaultProductImage className="h-full w-full" />
                  )}
                </div>
                <p className="text-center font-semibold">{category.name}</p>
              </div>
            ))}
          </InfiniteScroll>
        </>
      )}
    </div>
  );
};

export default Category;
