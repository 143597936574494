import { useTranslation } from "react-i18next";
import Map from "../../shared/components/map/Map";
import ContactInfo from "../../shared/components/contact-info/ContactInfo";
import ContactUsForm from "../../shared/components/contact-us-form/ContactUsForm";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <main className="flex max-h-[calc(100vh-90.3px)] w-full flex-col gap-6 overflow-y-auto pb-4">
      <section className="flex flex-col gap-4 lg:flex-row">
        {/* Contact Form */}
        <section className="w-full flex-1">
          <h1 className="text-2xl font-semibold">{t("contact.CONTACT_US")}</h1>
          <div className="my-2">{t("contact.DESCRIPTION")}</div>
          <hr></hr>
          <div className="mx-2 mt-6">
            <ContactUsForm />
          </div>
        </section>
        {/* Map */}
        <Map url={localStorage.getItem("map_link")} />
      </section>
      {/* Contact Details */}
      <ContactInfo />
    </main>
  );
};

export default ContactUs;
