import React, { useState, useEffect, useCallback } from "react";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import { PAGINATION_CONSTANTS } from "../../shared/constants/globalConstants";
import helperService from "../../services/helperService";
import { ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import NoDataPage from "../../shared/components/no-data-page/NoDataPage";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import debounce from "../../shared/utils/debounce";

const Orders = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("all");
  const [selectedOrderId] = useState("");
  const [page, setPage] = useState<number>(PAGINATION_CONSTANTS.PAGE);
  const per_page = PAGINATION_CONSTANTS.PER_PAGE; // Number of products per page
  const [orders, setOrders] = useState<any[]>([]);
  const [endOfScrollData, setEndOfScrollData] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [noDataPage, setNoDataPage] = useState<boolean>(false);
  const navigate = useNavigate();
  const tabs = [
    { key: t("common.ALL"), value: "all" },
    { key: t("common.COMPLETED"), value: "completed" },
    { key: t("common.PENDING"), value: "pending" },
  ];

  const breadcrumb = [
    { name: t("common.HOME"), path: "/" },
    { name: t("common.ORDERS"), path: "" },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // State for window width

  //get all user orders initially
  useEffect(() => {
    getOrders();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth < 500) setIsMobile(true);
    else setIsMobile(false);
  }, [windowWidth]);

  //get all user orders
  const getOrders = async (tab?: string) => {
    try {
      tab = tab ? tab.toLowerCase() : undefined;
      const res: any =
        tab === "all"
          ? await apiService.getOrders(page, per_page)
          : await apiService.getOrders(page, per_page, tab);

      res.data.orders.length < per_page && setEndOfScrollData(true);
      setOrders((prevOrders) => [...prevOrders, ...res.data.orders]);
    } catch (error) {
      if (initialLoad) {
        setNoDataPage(true);
      }
      setEndOfScrollData(true);
      const errorMessage = helperService.extractErrorMessage(error);
      toast.error(errorMessage);
    } finally {
      setInitialLoad(false);
    }
  };

  //format date and time to required format
  const getFormattedDate = (dateTime: string) => {
    return helperService.formatDate(dateTime);
  };

  // change page no on scroll
  const fetchMoreData = async () => {
    setScrolled(true);

    if (orders.length > 1) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  //to fetch more data when page changes on scoll
  useEffect(() => {
    if (scrolled) {
      // Fetch orders only if scrolled
      getOrders(selectedTab);
      setScrolled(false);
    }
  }, [page]);

  // Debounced version of filterOrders
  const debouncedFilterOrders = useCallback(
    debounce((tab: any) => {
      setPage(1);
      setEndOfScrollData(false);
      setOrders([]);
      setSelectedTab(tab.value);
    }, 500), // 500ms debounce time
    [],
  );

  //get order based on order status filter
  useEffect(() => {
    if (!initialLoad) {
      getOrders(selectedTab);
    }
  }, [selectedTab]);

  //navitage to order details page to get details of particular order
  const getOrderInfo = (order: any) => {
    navigate(`/order-details/${order.order_id}`);
  };

  return (
    <section className="max-h-[calc(100vh-90.3px)]">
      <div className="mb-2 flex flex-col space-y-4 lg:flex-row lg:space-y-0">
        <h2 className="text-left text-xl font-bold lg:text-left lg:text-2xl">
          {t("common.ORDERS")}
        </h2>
      </div>
      {/* Breadcrumbs */}
      {/* Conditionally Render Breadcrumb */}
      <div className="mb-6 mt-2 pl-1">
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <div className="mb-4">
        <hr />
      </div>
      {/* Tabs to select order filter type */}

      {noDataPage ? (
        <NoDataPage
          title={t("common.EMPTY_DATA")}
          message={t("common.MOVE_TO_PRODUCT_PAGE_MESSAGE")}
          buttonText={t("common.GO_TO_PRODUCT_PAGE")}
          link="/category/root"
        />
      ) : (
        <div className="flex overflow-y-auto">
          <div className="h-full w-full">
            {!initialLoad && (
              <div className="mb-4 flex gap-2 max-md:flex-col">
                {tabs.map((tab) => (
                  <button
                    key={tab.key}
                    onClick={() => debouncedFilterOrders(tab)}
                    className={`min-w-32 rounded px-4 py-2 font-semibold ${
                      selectedTab === tab.value
                        ? "bg-primary text-white"
                        : "border border-background-grey-200 bg-offwhite text-offblack"
                    }`}
                  >
                    {tab.key}
                  </button>
                ))}
              </div>
            )}

            {/* Infinite scroll on orders*/}
            <InfiniteScroll
              className="space-y-2"
              dataLength={orders.length}
              next={fetchMoreData}
              hasMore={!endOfScrollData}
              loader={
                <div
                  className="my-4 flex h-64 items-center justify-center"
                  data-testid="clip-loader"
                >
                  <ClipLoader color="#0000ff" loading={true} size={30} />
                </div>
              }
              height="58vh"
              scrollThreshold={0.9}
              endMessage={
                <div className="my-4 flex h-64 items-center justify-center">
                  <p className="text-center font-medium text-gray-600">
                    {orders.length > 0
                      ? t("common.END_OF_DATA_MESSAGE")
                      : t("common.NO_DATA_AVAILABLE")}
                  </p>
                </div>
              }
            >
              {orders.map((order) => (
                <div
                  key={order.order_no}
                  onClick={() => getOrderInfo(order)}
                  className={`mr-2 cursor-pointer space-y-2 rounded border bg-offwhite p-2 transition duration-200 ease-in-out md:p-4 ${
                    selectedOrderId === order.order_no
                      ? "border-primary-300 bg-primary-100"
                      : "border-background-grey-200"
                  } hover:border-primary-300 hover:bg-primary-100`}
                >
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="font-semibold">
                        {t("common.ORDER_TAG")}: {order?.order_no}
                      </div>
                      <div className="text-sm">
                        {t("common.DATE_TIME")}:{" "}
                        {getFormattedDate(order?.created_at)}
                      </div>
                      <div className="text-sm">
                        {t("common.STATUS")}:{" "}
                        {order?.order_status
                          .replace(/_/g, " ")
                          .toLowerCase()
                          .replace(/^./, (char: string) => char.toUpperCase())}
                      </div>
                      {isMobile && (
                        <div className="text-sm">
                          Order total: {t("common.CURRENCY_SYMBOL")}
                          {order?.total_amount}
                        </div>
                      )}
                    </div>

                    <div className="flex font-bold md:text-lg">
                      {!isMobile && (
                        <div className="ml-10 mr-20">
                          {t("common.CURRENCY_SYMBOL")}
                          {order?.total_amount}
                        </div>
                      )}

                      <div className="sm:ml-3 md:mr-3">
                        <ChevronRightIcon
                          className="mt-1.5 h-5 w-5"
                          strokeWidth="2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      )}
    </section>
  );
};

export default Orders;
