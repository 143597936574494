import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import {
  AddAddressSchema,
  EditAddressSchema,
} from "../../constants/validationSchemas";
import apiService from "../../../services/apiService";
import { TEXT_CONSTANTS } from "../../constants/globalConstants";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface IAddEditAddress {
  currentAddress?: any;
  setShowModal: (show: boolean) => void;
  resetAddresses: (response: any) => void;
  isLoading: boolean;
  fetchAddresses: any;
}

const AddEditAddress: React.FC<IAddEditAddress> = ({
  currentAddress,
  setShowModal,
  resetAddresses,
  fetchAddresses,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { countries } = useSelector((state: any) => state.countries);
  const [states, setStates] = useState<any>([]);
  const [selectedCountryId, setSelectedCountryId] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(
      currentAddress ? EditAddressSchema : AddAddressSchema,
    ),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      name: "",
      address_line1: "",
      address_line2: "",
      city: "",
      pincode: "",
      state: "",
      country: TEXT_CONSTANTS.COUNTRY_PLACEHOLDER,
      default_billing: false,
      default_shipping: false,
    },
  });

  useEffect(() => {
    if (currentAddress) {
      setValue("name", currentAddress.name);
      setValue("address_line1", currentAddress.address_line1);
      setValue("address_line2", currentAddress.address_line2);
      setValue("city", currentAddress.city);
      setValue("pincode", currentAddress.pincode);
      setValue("state", currentAddress.state);
      setValue("country", currentAddress.country);

      // Find the country ID for the currentAddress country name
      const foundCountry = countries.find(
        (c: any) => c.name === currentAddress.country,
      );
      if (foundCountry) {
        setSelectedCountryId(foundCountry.id);
        getStateByCountryId(foundCountry.id);
      }
    } else {
      reset(); // Reset to default values when adding a new address
      // Find the country ID for the currentAddress country name
      const foundCountry = countries.find((c: any) => c.name === "India");
      if (foundCountry) {
        setSelectedCountryId(foundCountry.id);
        getStateByCountryId(foundCountry.id);
      }
    }
  }, [currentAddress, reset, setValue, countries]);

  const getStateByCountryId = async (countryId: number) => {
    debugger;
    setIsLoading(true);
    setStates([]);
    try {
      const response: any = await apiService.getStateByCountryId(countryId);
      setStates(response);
    } catch (error) {
      toast.error("Error fetching states");
    } finally {
      setIsLoading(false);
    }
  };

  const handleCountryChange = (event: any) => {
    const countryName = event.target.value;
    setValue("country", countryName);
    setValue("state", "");

    const foundCountry = countries.find((c: any) => c.name === countryName);
    if (foundCountry) {
      setSelectedCountryId(foundCountry.id);
      getStateByCountryId(foundCountry.id);
    } else {
      setSelectedCountryId("");
      setStates([]);
    }
  };

  const handleAddAddress = async (data: any) => {
    setIsLoading(true);
    try {
      const payload = { ...data };
      const response = currentAddress
        ? await apiService.editUserAddress(currentAddress.address_id, payload)
        : await apiService.addUserAddress(payload);

      resetAddresses(response.data);
      toast.success(
        currentAddress
          ? TEXT_CONSTANTS.ADDRESS_UPDATED_SUCCESS
          : TEXT_CONSTANTS.ADDRESS_ADDED_SUCCESS,
      );
      setShowModal(false);
      fetchAddresses();
    } catch (error: any) {
      toast.error(
        error.response?.data?.message ||
          TEXT_CONSTANTS.ERROR_SUBMITTING_ADDRESS,
      );
      console.error("Error submitting address:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
      <div className="h-auto w-full max-w-xl overflow-y-auto rounded-md bg-white p-6 shadow-lg">
        <div className="item-center align-center flex justify-between">
          <h2 className="mb-4 text-2xl font-semibold">
            {currentAddress
              ? t("address_management.EDIT_ADDRESS")
              : t("address_management.ADD_NEW_ADDRESS_MODAL")}
          </h2>
          <div className="cursor-pointer">
            <div
              className="rounded-full bg-gray-100 p-2"
              data-testid="close-modal-icon"
            >
              <XMarkIcon
                width={20}
                height={20}
                onClick={() => {
                  setShowModal(false);
                  reset();
                }}
              />
            </div>
          </div>
        </div>
        <form
          className="flex flex-col gap-4"
          onSubmit={handleSubmit(handleAddAddress)}
        >
          <div>
            <div className="flex">
              <label htmlFor="name" className="pb-1">{t("user_info.NAME")}</label>
              <span className="text-error">
                {t("common.REQUIRED_FIELD_INDICATOR")}
              </span>
            </div>
            <input
              id="name"
              type="text"
              placeholder={t("place_holders.NAME")}
              {...register("name")}
              className="w-full rounded-md border p-2"
            />
            {errors.name?.message && (
              <p className="m-0 p-0 text-red-600">{errors.name?.message}</p>
            )}
          </div>

          <div>
            <div className="flex">
              <label htmlFor="address_line1" className="pb-1">
                {t("user_info.ADDRESS_LINE_1")}
              </label>
              <span className="text-error">
                {t("common.REQUIRED_FIELD_INDICATOR")}
              </span>
            </div>
            <input
              id="address_line1"
              type="text"
              placeholder={t("place_holders.ADDRESS_LINE1")}
              {...register("address_line1")}
              className="mt-0 w-full rounded-md border p-2"
            />
            {errors.address_line1?.message && (
              <p className="text-red-600">{errors.address_line1?.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="address_line2" className="pb-1">
              {t("user_info.ADDRESS_LINE_2")}
            </label>
            <input
              id="address_line2"
              type="text"
              placeholder={t("place_holders.ADDRESS_LINE2")}
              {...register("address_line2")}
              className="w-full rounded-md border p-2"
            />
            {errors.address_line2?.message && (
              <p className="text-red-600">{errors.address_line2?.message}</p>
            )}
          </div>

          <div>
            <div className="flex">
              <label htmlFor="country" className="pb-1">{t("user_info.COUNTRY")}</label>
              <span className="text-error">
                {t("common.REQUIRED_FIELD_INDICATOR")}
              </span>
            </div>
            <select
              id="country"
              {...register("country")}
              className="w-full rounded-md border p-2"
              onChange={handleCountryChange}
              defaultValue={
                currentAddress
                  ? currentAddress.country
                  : TEXT_CONSTANTS.COUNTRY_PLACEHOLDER
              }
            >
              <option value={TEXT_CONSTANTS.COUNTRY_PLACEHOLDER}>
                {t("place_holders.COUNTRY")}
              </option>
              {countries?.map((country: any, index: number) => (
                <option key={index} value={country.name}>
                  {country.name}
                </option>
              ))}
            </select>
            {errors.country?.message && (
              <p className="text-red-600">{errors.country?.message}</p>
            )}
          </div>

          <div>
            <div className="flex">
              <label htmlFor="state" className="pb-1">{t("user_info.STATE")}</label>
              <span className="text-error">
                {t("common.REQUIRED_FIELD_INDICATOR")}
              </span>
            </div>
            <select
              value={watch("state")}
              id="state"
              {...register("state")}
              className="w-full rounded-md border p-2"
              disabled={!selectedCountryId}
            >
              <option value="" disabled>
                {selectedCountryId
                  ? t("common.select_state")
                  : t("common.select_country_first")}
              </option>
              {states.map((state: any) => (
                <option key={state.id} value={state.name}>
                  {state.name}
                </option>
              ))}
            </select>
            {errors.state?.message && (
              <p className="text-red-600">{errors.state?.message}</p>
            )}
          </div>

          <div>
            <div className="flex">
              <label htmlFor="city" className="pb-1">{t("user_info.CITY")}</label>
              <span className="text-error">
                {t("common.REQUIRED_FIELD_INDICATOR")}
              </span>
            </div>
            <input
              id="city"
              type="text"
              placeholder={t("place_holders.CITY")}
              {...register("city")}
              className="w-full rounded-md border p-2"
            />
            {errors.city?.message && (
              <p className="text-red-600">{errors.city?.message}</p>
            )}
          </div>

          <div>
            <div className="flex">
              <label htmlFor="pincode" className="pb-1">{t("user_info.PINCODE")}</label>
              <span className="text-error">
                {t("common.REQUIRED_FIELD_INDICATOR")}
              </span>
            </div>
            <input
              id="pincode"
              type="text"
              placeholder={t("place_holders.PINCODE")}
              {...register("pincode")}
              className="w-full rounded-md border p-2"
            />
            {errors.pincode?.message && (
              <p className="text-red-600">{errors.pincode?.message}</p>
            )}
          </div>

          <div className="mt-4 flex justify-center">
            <button
              type="submit"
              className="button-color rounded-md bg-blue-600 px-10 py-3 text-white"
              disabled={isLoading}
            >
              {isLoading
                ? t("address_management.SAVING_ADDRESS")
                : t("address_management.SAVE_ADDRESS")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditAddress;
