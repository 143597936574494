import { useEffect, useState } from "react";
import apiService from "../../services/apiService";
import { toast } from "react-toastify";
import {
  PencilSquareIcon,
  TrashIcon,
  MapIcon,
} from "@heroicons/react/24/outline";
import { TrashIcon as TrashIconSolid } from "@heroicons/react/24/solid";
import { useForm } from "react-hook-form";
import AddressPopup from "../../shared/components/add-edit-address/AddEditAddress";
import DialogueBox from "../../shared/components/dialogue-box/DialogueBox";
import helperService from "../../services/helperService";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../shared/components/breadcrumb/Breadcrumb";

const Address = () => {
  const [addresses, setAddresses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [addressToDelete, setAddressToDelete] = useState(null);
  const [currentAddress, setCurrentAddress] = useState(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumb = [
    { name: t("common.HOME"), path: "/" },
    { name: t("bread_crums.ADDRESS"), path: "" },
  ];
  const { reset, setValue } = useForm({});

  const fetchAddresses = async () => {
    setIsLoading(true);
    try {
      const response = await apiService.getUserAddress();
      setAddresses(response.data.addresses);
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handelUpdateDefaultShipping = async (data) => {
    setIsLoading(true);
    try {
      const response = await apiService.editUserAddress(data.address_id, {
        default_shipping: !data.default_shipping,
      });
      toast.success(helperService.extractSuccessMessage(response));
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
      fetchAddresses();
    }
  };

  const handelUpdateDefaultBilling = async (data) => {
    setIsLoading(true);
    try {
      const response = await apiService.editUserAddress(data.address_id, {
        default_billing: !data.default_billing,
      });
      toast.success(helperService.extractSuccessMessage(response));
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
      fetchAddresses();
    }
  };

  const handleDeleteAddress = async () => {
    setIsLoading(true);
    try {
      const res = await apiService.deleteUserAddress(addressToDelete);
      setAddresses((prev) =>
        prev.filter((address) => address.address_id !== addressToDelete),
      );
      setShowDeleteDialog(false);
      toast.success(helperService.extractSuccessMessage(res));
    } catch (error) {
      toast.error(helperService.extractErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAddresses();
  }, []);

  return (
    <section className="max-h-[calc(100vh-90.3px)]">
      <div className="mb-4 flex items-center justify-between border-b pb-4">
        <div className="item-center flex w-full flex-col">
          <div className="flex w-full justify-between max-md:flex-col max-md:items-center max-md:gap-2">
            <h1 className="text-2xl font-bold">
              {t("address_management.SAVED_ADDRESS_HEADER")}
            </h1>
            {addresses.length ? (
              <button
                onClick={() => {
                  setCurrentAddress(null);
                  setShowModal(true);
                }}
                className="items-center rounded-md bg-success px-4 py-2 text-white max-md:min-w-full"
              >
                &#43; {t("address_management.ADD_NEW_ADDRESS")}
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="mt-2">
            <Breadcrumb breadcrumb={breadcrumb} />
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <div
            className="inline-block size-10 animate-spin rounded-full border-[3px] border-current border-t-transparent text-blue-600 dark:text-blue-500"
            role="status"
            aria-label="loading"
          >
            <span className="sr-only">{t("common.LOADING")}</span>
          </div>
        </div>
      ) : (
        <>
          {addresses.length ? (
            <div className="max-h-[calc(100vh-200.3px)] overflow-y-auto">
              {addresses.map((address, index) => (
                <div
                  key={index}
                  className="my-2 flex flex-col justify-between gap-2 rounded-md border border-background-grey-200 px-4 py-4 duration-100 hover:bg-primary/5 xl:flex-row"
                >
                  <div>
                    <div>
                      <p>
                        <p className="font-semibold">
                          {address.name}

                          {(address.default_shipping ||
                            address.default_billing) && (
                            <span className="mx-2 font-semibold text-warning-300">
                              |
                            </span>
                          )}

                          <span className="font-semibold text-warning-300">
                            {address.default_shipping &&
                              t("address_management.DEFAULT_SHIPPING")}
                          </span>

                          {address.default_shipping &&
                            address.default_billing && (
                              <span className="mx-2 font-semibold text-warning-300">
                                |
                              </span>
                            )}

                          <span className="font-semibold text-warning-300">
                            {address.default_billing &&
                              t("address_management.DEFAULT_BILLING")}
                          </span>
                        </p>

                        <p
                          title={`${address.address_line1}${
                            address.address_line2
                              ? `, ${address.address_line2}`
                              : ""
                          }, ${address.city}, ${address.state}, ${
                            address.country
                          } - ${address.pincode}`}
                          className="text-ellipsi max-w-[50ch] overflow-hidden font-semibold max-md:max-w-[40ch]"
                        >
                          {`${address.address_line1}${
                            address.address_line2
                              ? `, ${address.address_line2}`
                              : ""
                          }, ${address.city}, ${address.state}, ${
                            address.country
                          } - ${address.pincode}`}
                        </p>
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-3 sm:justify-between max-md:flex-col">
                    <div className="flex justify-between gap-3 max-sm:flex-col">
                      <div
                        onClick={() => handelUpdateDefaultBilling(address)}
                        className={`flex cursor-pointer items-center space-x-2 rounded-md border px-3 py-3 ${
                          address.default_billing
                            ? "bg-success/10 text-success"
                            : "bg-gray-100"
                        }`}
                      >
                        <input
                          type="checkbox"
                          id="checkbox1"
                          className="form-checkbox h-5 w-5 cursor-pointer accent-success"
                          checked={address.default_billing}
                          disabled={isLoading}
                        />
                        <label
                          for="checkbox1"
                          className={`cursor-pointer ${
                            address.default_billing ? "text-success" : ""
                          } font-semibold`}
                        >
                          {t("address_management.DEFAULT_BILLING")}
                        </label>
                      </div>

                      <div
                        onClick={() => handelUpdateDefaultShipping(address)}
                        className={`flex cursor-pointer items-center space-x-2 rounded-md border px-3 py-3 ${
                          address.default_shipping
                            ? "bg-success/10 text-success"
                            : "bg-gray-100"
                        }`}
                      >
                        <input
                          type="checkbox"
                          id="checkbox2"
                          className="form-checkbox h-5 w-5 cursor-pointer accent-success"
                          checked={address.default_shipping}
                          disabled={isLoading}
                        />
                        <label
                          for="checkbox2"
                          className={`cursor-pointer ${
                            address.default_shipping ? "text-success" : ""
                          } font-semibold`}
                          checked={address.default_billing}
                        >
                          {t("address_management.DEFAULT_SHIPPING")}
                        </label>
                      </div>
                    </div>

                    <div className="flex justify-between gap-3">
                      <button
                        className="rounded-md border bg-error/10 p-2"
                        onClick={() => {
                          setAddressToDelete(address.address_id);
                          setShowDeleteDialog(true);
                        }}
                      >
                        <TrashIcon
                          className="h-8 w-8 text-error"
                          width={15}
                          height={15}
                        />
                      </button>

                      <button
                        className="rounded-md border bg-primary/10 p-2"
                        id={address.address_id}
                        onClick={() => {
                          setCurrentAddress(address);
                          setShowModal(true);
                          setValue("name", address.name);
                          setValue("address_line1", address.address_line1);
                          setValue("address_line2", address.address_line2);
                          setValue("city", address.city);
                          setValue("pincode", address.pincode);
                          setValue("state", address.state);
                          setValue("country", address.country);
                          setValue("default_billing", address.default_billing);
                          setValue(
                            "default_shipping",
                            address.default_shipping,
                          );
                        }}
                      >
                        <PencilSquareIcon
                          width={15}
                          height={15}
                          className="h-8 w-8 text-primary"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="mt-4 flex h-[40rem] flex-col items-center justify-center rounded-xl border bg-offwhite p-4 text-center">
              <MapIcon
                width={10}
                height={10}
                className="h-20 w-20 stroke-current text-background-grey-500"
              />
              <p className="my-2 mt-4 text-xl font-bold">
                {t("address_management.NO_SAVED_ADDRESSES")}
              </p>
              <p className="text-sm">
                {t("address_management.NO_ADDRESSES_MESSAGE")}
              </p>
              <button
                onClick={() => {
                  setCurrentAddress(null);
                  setShowModal(true);
                }}
                className="button-color mt-4 rounded-md bg-blue-600 px-4 py-2 text-white"
              >
                {t("address_management.ADD_NEW_ADDRESS")}
              </button>
            </div>
          )}
        </>
      )}
      {showModal && (
        <AddressPopup
          currentAddress={currentAddress}
          setShowModal={setShowModal}
          resetAddresses={reset}
          fetchAddresses={fetchAddresses}
        />
      )}
      {showDeleteDialog && (
        <DialogueBox
          icon={
            <TrashIconSolid width={40} height={40} className="text-error" />
          }
          heading={t("address_management.DELETE_CONFIRMATION_TITLE")}
          message={t("address_management.DELETE_CONFIRMATION_MESSAGE")}
          cancel={t("common.CANCEL")}
          confirm={t("address_management.DELETE_ADDRESS")}
          handleConfirm={handleDeleteAddress}
          setShowDialog={setShowDeleteDialog}
          isLoading={isLoading}
          iconGradient="bg-error/10"
        ></DialogueBox>
      )}
    </section>
  );
};

export default Address;
