import { useState } from "react";
import { useTranslation } from "react-i18next";

const TermsAndConditions = () => {
  const { t } = useTranslation();
  const [content] = useState({
    title: "Terms & Conditions",
    lastUpdated: "26 April 2025",
    sections: [
      {
        heading: "",
        text: "",
      },
    ],
  });

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      {/* Header */}
      <div className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-semibold">{t("termsAndConditions.headerGreeting")}</h2>
        <p className="mt-1 text-lg">
          {t("termsAndConditions.headerDescription", {
            terms: <span className="font-semibold">{content.title}</span>,
          })}
        </p>
      </div>

      {/* Terms Content */}
      <div className="mt-6 p-6 bg-white shadow-lg rounded-lg">
        <h1 className="text-2xl font-bold">{content.title}</h1>
        <p className="text-gray-500 text-sm">Last updated: {content.lastUpdated}</p>

        {content.sections.map((section, index) => (
          <div key={index} className="mt-4">
            <h2 className="text-lg font-semibold">{section.heading}</h2>
            <p className="text-gray-700">{section.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TermsAndConditions;
